import { Link, graphql } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

import HeroIconOutline from "../components/heroicons";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import SEO from "../components/seo";

function List3Col({ features }) {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          {features.features.map((item, idx) => {
            return (
              <div key={idx} className="mt-10 lg:mt-0 first:mt-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <HeroIconOutline name={item.icon} size="h-6 w-6" />
                </div>
                <div className="mt-5">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">
                    {item.header}
                  </h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    {item.subheader}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

List3Col.propTypes = {
  features: PropTypes.object.isRequired,
};

function FeatureList({ features }) {
  switch (features.template) {
    case "3col":
      return <List3Col features={features} />;
  }
}

FeatureList.propTypes = {
  features: PropTypes.object.isRequired,
};

export default function FeaturePage({ data }) {
  const feature = data.featuresYaml;

  return (
    <div>
      <SEO keywords={feature.seo.keywords} title={feature.seo.title} />

      <Navbar />

      <div className="bg-gray-900 pb-10">
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                {feature.hero_unit.eyebrow}
              </h2>
              <p
                className="mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none"
                dangerouslySetInnerHTML={{ __html: feature.hero_unit.header }}
              />
              <p className="mt-2 text-xl leading-7 text-gray-300">
                {feature.hero_unit.subheader}
              </p>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <Link
                    to={feature.hero_unit.cta_primary.to}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    {feature.hero_unit.cta_primary.text}
                  </Link>
                </div>
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <Link
                    to={feature.hero_unit.cta_secondary.to}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    {feature.hero_unit.cta_secondary.text}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeatureList features={feature.feature_list} />

      <Footer />
    </div>
  );
}

FeaturePage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query($slug: String!) {
    featuresYaml(fields: { slug: { eq: $slug } }) {
      seo {
        keywords
        title
      }
      hero_unit {
        template
        eyebrow
        header
        subheader
        cta_primary {
          text
          to
        }
        cta_secondary {
          text
          to
        }
      }
      feature_list {
        template
        features {
          header
          icon
          subheader
        }
      }
    }
  }
`;
